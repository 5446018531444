<template>
  <div class="d-flex h-100 align-items-center justify-content-around">
    <div class="d-none d-lg-block d-lx-block">
      <h1>
        Tu propia flotilla,
        <br />sin inversión
      </h1>
    </div>
    <z-card>
      <img class="position-top" src="@/assets/img/vertical-logo.png" />
      <div class="message p-3">
        <p>Temporalmente fuera de servicio</p>
        <p>Por favor intenta iniciar sesión más tarde.</p>
      </div>
      <router-link :to="{ name: 'login' }">
        <z-button class="mb-3">Ingresar a Zubut</z-button>
      </router-link>
    </z-card>
  </div>
</template>

<script>
import ZCard from "./ZCard";

export default {
  name: "Reconnect",
  components: { ZCard }
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 50px;
}

.message p:first-child {
  font-size: 16px;
}

img {
  width: 150px;
  height: 150px;
}
</style>
